<template>
  <div>
    <div>
      <b-row>
        <b-col sm="4">
          <b-card>
            <div class="text-center">
              <b-card-title>
                <b-icon
                  class="p-2"
                  icon="people"
                  font-scale="6"
                />
                <br>Utilisateur
              </b-card-title>
              <div class="p-0">
                <b-link :to="{ name: 'app-settings-users' }">
                  Ajouter
                </b-link> | <b-link :to="{ name: 'app-settings-users-listing' }">
                  Liste
                </b-link>
              </div>
            </div>
          </b-card>
        </b-col>

        <b-col sm="4">
          <b-card>
            <div class="text-center">
              <b-card-title>
                <b-icon
                  class="p-2"
                  icon="layers"
                  font-scale="6"
                />
                <br>Nature Cargaison
              </b-card-title>
              <div class="p-0">
                <b-link :to="{ name: 'app-settings-nature-cargo' }">
                  Ajouter
                </b-link> | <b-link :to="{ name: 'app-settings-nature-cargo-listing' }">
                  Liste
                </b-link>
              </div>
            </div>
          </b-card>
        </b-col>

        <b-col sm="4">
          <b-card>
            <div class="text-center">
              <b-card-title>
                <b-icon
                  class="p-2"
                  icon="award"
                  font-scale="6"
                />
                <br>Type de Cotation
              </b-card-title>
              <div class="p-0">
                <b-link :to="{ name: 'app-settings-quotation' }">
                  Ajouter
                </b-link> | <b-link :to="{ name: 'app-settings-quotation-listing' }">
                  Liste
                </b-link>
              </div>
            </div>
          </b-card>
        </b-col>

        <b-col sm="4">
          <b-card>
            <div class="text-center">
              <b-card-title>
                <b-icon
                  class="p-2"
                  icon="life-preserver"
                  font-scale="6"
                />
                <br>Opération Navire
              </b-card-title>
              <div class="p-0">
                <b-link :to="{ name: 'app-settings-ship-operation-add' }">
                  Ajouter
                </b-link> | <b-link :to="{ name: 'app-settings-ship-operation-listing' }">
                  Liste
                </b-link>
              </div>
            </div>
          </b-card>
        </b-col>

        <b-col sm="4">
          <b-card>
            <div class="text-center">
              <b-card-title>
                <b-icon
                  class="p-2"
                  icon="basket2"
                  font-scale="6"
                />
                <br>Type de Navire
              </b-card-title>
              <div class="p-0">
                <b-link :to="{ name: 'app-settings-ship-nature-add' }">
                  Ajouter
                </b-link> | <b-link :to="{ name: 'app-settings-ship-nature-listing' }">
                  Liste
                </b-link>
              </div>
            </div>
          </b-card>
        </b-col>

        <b-col sm="4">
          <b-card>
            <div class="text-center">
              <b-card-title>
                <b-icon
                  class="p-2"
                  icon="geo"
                  font-scale="6"
                />
                <br>Consignataire
              </b-card-title>
              <div class="p-0">
                <b-link :to="{ name: 'app-settings-ship-consignee-add' }">
                  Ajouter
                </b-link> | <b-link :to="{ name: 'app-settings-ship-consignee-listing' }">
                  Liste
                </b-link>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
export default {

}
</script>
